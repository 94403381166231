(function () {
  'use strict';

  const helpers = {
    toTop() {
      function SVS_B(eAmt, where) {
        if (where === 'center' || where === '') window.scrollBy(0, eAmt / 2);
        if (where === 'top') window.scrollBy(0, eAmt);
      }

      function SmoothVerticalScrolling(e, time, where) {
        const eTop = e.getBoundingClientRect().top;
        const eAmt = eTop / 100;
        let curTime = 0;
        while (curTime <= time) {
          window.setTimeout(SVS_B, curTime, eAmt, where);
          curTime += time / 100;
        }
      }
      const iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

      if (iOS) {
        SmoothVerticalScrolling(window, 300, 0);
      } else {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
    forEach(el, func) {
      const count = el.length;

      for (let num = 0; num < count; num += 1) {
        func(el[num]);
      }
    },
    css(el, css) {
      const elem = el;
      helpers.forEach(Object.keys(css), val => {
        elem.style[val] = css[val];
      });
    },
    animate(el, css, speed = 10) {
      const elem = el;
      setTimeout(() => {
        helpers.css(elem, css);
      }, speed);
    },
    topPageShow(el, css) {
      const elem = el;
      const currScroll = window.pageYOffset;
      const containerPos = document.querySelector('.container').offsetTop * 0.5;
      const topPage = elem;

      if (currScroll > containerPos) {
        helpers.animate(topPage, css);

        return false;
      }

      helpers.animate(topPage, {
        opacity: 0,
        bottom: '-100%',
      });

      return false;
    },
  };

  document.querySelector('.footer__top-page').onclick = function() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  document.querySelector('.footer__top-page-img-sp').onclick = function() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const closeMenu = function() {
    const closeMenuTrigger = document.querySelectorAll('.js-close-menu');
    helpers.forEach(closeMenuTrigger, item => {
      item.onclick = function() {
        helpers.css(document.querySelector('.sidebar__menu--sp'), {
          right: '-100%',
        });
        helpers.css(document.querySelector('.sidebar__overlay'), {
          opacity: 0,
        });

        setTimeout(() => {
          document.querySelector('.sidebar__menu--sp').style.display = 'none';
          document
            .querySelector('.sidebar--sp')
            .removeChild(document.querySelector('.sidebar__overlay'));
        }, 300);
      };
    });
  };

  document.querySelector('.sidebar__menu-icon').onclick = function() {
    const overlay = document.createElement('DIV');
    overlay.classList.add('sidebar__overlay', 'js-close-menu');

    document.querySelector('.sidebar--sp').appendChild(overlay);
    document.querySelector('.sidebar__menu--sp').style.display = 'block';
    setTimeout(() => {
      helpers.css(overlay, {
        opacity: 1,
      });
      helpers.css(document.querySelector('.sidebar__menu--sp'), {
        right: 0,
      });
    }, 10);

    closeMenu();
  };

  window.onscroll = function() {
    if (this.screen.width > 767) {
      helpers.topPageShow(document.querySelector('.footer__top-page'), {
        opacity: 1,
        bottom: '50px',
      });
    } else {
      helpers.topPageShow(document.querySelector('.footer__top-page-sp'), {
        opacity: 1,
        bottom: '50px',
      });
    }
  };

  const radioButtons = document.querySelectorAll(
    'input[type=radio][name="terms"]'
  );
  if (radioButtons) {
    const agreeButton = document.querySelector('#agree');

    helpers.forEach(radioButtons, radio => {
      radio.addEventListener('change', () => {
        if (agreeButton.checked) {
          document
            .querySelector('input[type=submit]')
            .classList.remove('button--disabled');
        } else {
          document
            .querySelector('input[type=submit]')
            .classList.add('button--disabled');
        }
      });
    });
  }

  // Email Confirm Input Validation
  const email = document.querySelector('#email');
  const emailConfirm = document.querySelector('#email-confirm');
  const emailStatus = document.querySelector('#emailStatus');
  let status = '';
  let message = '';

  if (email && emailConfirm) {
    const emailArray = [email, emailConfirm];
    const emailError = document.querySelector(
      '.wpcf7-form-control-wrap.email .wpcf7-not-valid-tip'
    );
    const emailConfirmError = document.querySelector(
      '.wpcf7-form-control-wrap.email-confirm .wpcf7-not-valid-tip'
    );
    let emailInput = document.querySelector('#email').value;
    let emailConfirmInput = emailConfirm.value;

    helpers.forEach(emailArray, mail => {
      mail.onkeyup = function() {
        emailInput = email.value;
        emailConfirmInput = emailConfirm.value;

        if (emailInput.length > 0 && emailConfirmInput.length > 0) {
          if (!emailError || !emailConfirmError) {
            if (emailInput !== emailConfirmInput) {
              status = '';
            } else {
              status = 'confirmed';
            }
          }
        }
        emailStatus.value = status;
      };
    });

    if (emailInput.length > 0 && emailConfirmInput.length > 0) {
      if (!emailError && !emailConfirmError) {
        if (emailInput !== emailConfirmInput) {
          message = '入力されたメールアドレスに間違いがあります。';
        } else {
          message = '';
          emailStatus.value = 'confirmed';
        }
      }
    }

    const errorMessage = document.createElement('SPAN');
    const element = document.querySelector(
      '.wpcf7-form-control-wrap.email-confirm'
    );
    errorMessage.classList.add('wpcf7-not-valid-tip');
    errorMessage.innerHTML = message;
    element.appendChild(errorMessage);
  }

  const tabs = document.querySelectorAll('.js-work-tabs');
  if (tabs) {
    helpers.forEach(tabs, tab => {
      tab.onclick = function() {
        this.classList.add('work__tab-item--active');
      };
    });
  }

  const slideshow = function(data = []) {
    const { images, speed, effect } = data;
    const numImg = images.length;

    for (let i = 0; i < numImg; i += 1) {
      const contControls = document.querySelector('.banner__slideshow-controls');
      const controls = document.createElement('DIV');
      controls.classList.add('banner__slideshow-circle');
      controls.setAttribute('data-index', i);

      contControls.appendChild(controls);

      const contImage = document.querySelector('.banner__list');
      const image = document.createElement('LI');
      image.classList.add('banner__item');

      const link  = document.createElement('A');
      link.setAttribute('href', images[i].link);
      link.innerHTML = `
      <img class="banner__slideshow-img" src="${images[i].img}" alt="${images[i].alt}">
      <img class="banner__slideshow-img banner__slideshow-img--sp" src="${images[i].imgSp}" alt="${images[i].alt}">
    `;

      if (images[i].text1) {
        link.innerHTML += `
        <div class="banner__bottom-container">
          <div class="banner__bottom-heading-wrapper">
            <div class="banner__bottom-label-wrapper">
              <label class="banner__bottom-label">${images[i].label}</label>
            </div>
            <h2 class="banner__bottom-heading">${images[i].text1}<br/>${images[i].text2}</h2>
          </div>
        </div>`;
      }

      image.appendChild(link);
      contImage.appendChild(image);
    }

    document
      .querySelectorAll('.banner__slideshow-circle')[0]
      .classList.add('banner__slideshow-circle--active');

    let interval;

    const slideshowAnimation = num => {
      if (effect === 'fade') {
        let queue = Number(num) === numImg ? 0 : Number(num);
        const banners = document.querySelectorAll('.banner__item');
        const controls = document.querySelectorAll('.banner__slideshow-circle');

        interval = setInterval(() => {
          for (let i = 0; i < numImg; i += 1) {
            banners[i].style.display = 'none';
            banners[i].style.opacity = 0;
            controls[i].classList.remove('banner__slideshow-circle--active');
          }

          banners[queue].style.display = 'block';
          controls[queue].classList.add('banner__slideshow-circle--active');

          setTimeout(() => {
            banners[queue].style.opacity = 1;

            queue += 1;
            if (queue === numImg) queue = 0;

            const headingState = queue !== 1 ? 'none' : 'flex';
            document.querySelector(
              '.banner__container'
            ).style.display = headingState;
          }, 1);
        }, speed);

        helpers.forEach(
          document.querySelectorAll('.banner__slideshow-circle'),
          el => {
            const elem = el;

            elem.onclick = function() {
              const bannersClick = document.querySelectorAll('.banner__item');
              const controlsClick = document.querySelectorAll(
                '.banner__slideshow-circle'
              );

              let queueClick = this.dataset.index;
              for (let i = 0; i < numImg; i += 1) {
                bannersClick[i].style.display = 'none';
                bannersClick[i].style.opacity = 0;
                controlsClick[i].classList.remove(
                  'banner__slideshow-circle--active'
                );
              }

              bannersClick[queueClick].style.display = 'block';
              controlsClick[queueClick].classList.add(
                'banner__slideshow-circle--active'
              );

              setTimeout(() => {
                bannersClick[queueClick].style.opacity = 1;
                clearInterval(interval);
                slideshowAnimation(queueClick);
                queueClick += 1;
                if (queueClick === numImg) queueClick = 0;
                const headingState = queueClick !== '01' ? 'none' : 'flex';
                document.querySelector(
                  '.banner__container'
                ).style.display = headingState;
              }, 1);
            };
          }
        );
      }
    };

    slideshowAnimation(1);
  };

  const scrollToLink = () => {
    return document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function(e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  };

  const faqToggle = () => {
    const checkboxElement = document.querySelectorAll('.js-faq-toggle');
    [...checkboxElement].forEach(checkbox => {
      checkbox.addEventListener('click', el => {
        let maxHeight = 0;
        const answerElement = el.target.parentElement.getElementsByClassName(
          'list__faq-answer'
        )[0];

        if (!el.target.checked) {
          answerElement.style.maxHeight = `0px`;
          return;
        }

        [...answerElement.children].forEach(child => {
          maxHeight += child.getBoundingClientRect().height;
          if (child.classList.contains('list__faq-button')) maxHeight += 22;
        });

        answerElement.style.maxHeight = `${maxHeight}px`;
      });
    });
  };

  const bannerSlide = document.querySelector('.js-banner-slide');
  if (bannerSlide) {
    const images = [];
    helpers.forEach(document.querySelectorAll('.banner__data'), el => {
      const elem = el;
      const data = {
        img: elem.dataset.img,
        imgSp: elem.dataset.imgsp,
        alt: elem.dataset.alt,
        label: elem.dataset.label,
        text1: elem.dataset.text1,
        text2: elem.dataset.text2,
        link: elem.dataset.link
      };
      images.push(data);
    });

    slideshow({
      images,
      speed: 5e3,
      effect: 'fade',
      content: '',
    });

    const topAnimation = document.querySelector('.js-top-animation');
    setTimeout(() => {
      topAnimation.classList.remove('js-top-animation--loading'); // remove loading class after page loads
    }, 500);

    /* global AOS */
    AOS.init({
      disable: () => {
        const maxWidth = 767;
        return window.innerWidth < maxWidth;
      },
      offset: 150,
      duration: 800,
      once: true,
    });
  }

  scrollToLink();
  faqToggle();

}());
